import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRequest } from 'ahooks'
import dayjs from 'dayjs'
import { Badge, Button, DatePicker, Input, message, Modal, Popover, Select, Space } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { DownloadOutlined, InfoCircleOutlined } from '@ant-design/icons'

import { CellFormatSum } from '@/components/CellFormatSum'
import { CusTable, TCusTableIns } from '@/components/CusTable'
import { SPIN_DELAY } from '@/components/Loading'
import { useDateRangePresets } from '@/hooks/hooks'
import { useControllerRef } from '@/hooks/useAbortController'
import { filePick } from '@/utils/filePick'
import { DEFAULT_PAGE_SIZE, getDefaultPaginationProps } from '@/utils/pagination'
import { fileValidate } from '@/utils/utils'
import { NumPrivacyShow } from '../../components/NumPrivacyShow'
import { SuccessAndFailResultModal } from '../../components/SuccessAndFailResultModal'
import { DetailsDrawer } from '../Recharge'
import { getDisabledDateRangeFn, useRule } from '../utils'
import { apiGetRecords, apiImport } from './apis'
import { IRecord, IReqData, IRes } from './interface'
import { popupSlot, useMATCH_STATUS_NAME_MAP } from './utils'
import styles from './styles.module.less'

export const BankStatement = () => {
  const { t } = useTranslation()
  const userRule = useRule()
  const dateRangePresets = useDateRangePresets()
  const matchStatusNameMap = useMATCH_STATUS_NAME_MAP()

  const [matchStatus, setMatchStatus] = useState<IReqData['matchStatus']>(0)
  const [companyCardNumber, setCompanyCardNumber] = useState<IReqData['companyCardNumber']>()
  const [userCardNumber, setUserCardNumber] = useState<IReqData['userCardNumber']>()
  const [receivedDateBegin, setReceivedDateBegin] = useState<IReqData['receivedDateBegin']>(() =>
    dateRangePresets.last30days.value[0].format($F_YMD),
  )
  const [receivedDateEnd, setReceivedDateEnd] = useState<IReqData['receivedDateEnd']>(() =>
    dateRangePresets.last30days.value[1].format($F_YMD),
  )
  const [page, setPage] = useState<IReqData['page']>(1)
  const [size, setSize] = useState<IReqData['size']>(DEFAULT_PAGE_SIZE)

  const reqData: IReqData = {
    matchStatus,
    companyCardNumber,
    userCardNumber,
    receivedDateBegin,
    receivedDateEnd,
    page,
    size,
  }
  const tableRef = useRef<TCusTableIns>(null)
  const controllerRef = useControllerRef()
  const {
    loading,
    data,
    params: [param],
    run: getList,
  } = useRequest<IRes, [IReqData]>(
    req => {
      controllerRef.current.abort()
      controllerRef.current = new AbortController()
      return apiGetRecords(req, controllerRef.current.signal)
    },
    {
      defaultParams: [reqData],
      onBefore: () => CusTable.scrollToTop(tableRef.current),
    },
  )

  const columns: ColumnsType<IRecord> = [
    {
      title: t('Transaction.shang-jia-yin-hang-ka-zhang-hao'),
      render: (_v, { userCardNumber: v }) => <NumPrivacyShow value={v} />,
    },
    {
      title: t('Transaction.gong-si-yin-hang-ka-zhang-hao'),
      render: (_v, { companyCardNumber: v }) => <NumPrivacyShow value={v} />,
    },
    {
      title: t('Transaction.shi-ji-dao-zhang-jin-e'),
      width: 120,
      align: 'right',
      render: (_v, { receivedAmount: v, receivedCurrencyType }) => (
        <CellFormatSum value={v} currency={receivedCurrencyType} />
      ),
    },
    {
      title: t('Transaction.shi-ji-dao-zhang-ri-qi'),
      render: (_v, { receivedDate: v }) => v && dayjs(v).format($F_YMD),
    },
    {
      title: t('Transaction.dao-zhang-yin-hang-liu-shui-hao'),
      render: (_v, { receivedBankStatement: v }) => v,
    },
    {
      title: t('Transaction.jiao-yi-id'),
      hidden: userRule !== 'finance',
      render: (_v, { transactionId: v }) => v,
    },
    {
      title: t('2-common.zhuang-tai'),
      width: 100,
      render: (_v, { matchStatus: v }) => (
        <Badge color={{ 0: 'red', 1: 'green' }[v] || $COLOR.statusDefault} text={matchStatusNameMap[v] || v} />
      ),
    },
    {
      title: t('Transaction.shang-jia-ming-cheng'),
      hidden: userRule !== 'employee',
      render: (_v, { merchantUserName: v }) => v,
    },
    {
      title: t('2-common.cao-zuo'),
      width: 125,
      hidden: userRule !== 'employee',
      render: (_v, record) => {
        const { merchantUserId } = record
        if (userRule === 'employee') {
          return (
            <Button
              type="link"
              disabled={!merchantUserId}
              onClick={() => {
                if (!merchantUserId) return
                const updatePopup = popupSlot.insert(
                  <DetailsDrawer
                    mode="submit"
                    props={{
                      merchantUserId,
                      beforeFillData: {
                        merchantUserId,
                        payCardNumber: record.userCardNumber,
                      },
                    }}
                    destroy={() => updatePopup(null)}
                  />,
                )
              }}
            >
              {t('Transaction.ti-jiao-chong-zhi')}
            </Button>
          )
        }
      },
    },
  ]

  const handleImport = () => {
    filePick({
      accept: '.xlsx',
      onFilePick: ([file]) => {
        const msg = fileValidate(file, { accept: '.xlsx', max: 10 })
        if (msg) return void message.error(msg)

        Modal.confirm({
          title: t('Transaction.que-ren-yao-dao-ru-ma'),
          content: file.name,
          onOk: async () => {
            const result = await apiImport(file)
            SuccessAndFailResultModal.open({
              result,
              onClose: () => {
                if (result.successTotal > 0) {
                  setPage(1)
                  getList({ ...reqData, page: 1 })
                }
              },
            })
          },
        })
      },
    })
  }

  return (
    <div className={styles.box}>
      <div className={styles.header}>
        <div className={styles.header_filter}>
          <Space size={16}>
            {userRule === 'finance' && (
              <Space size={0}>
                {t('2-common.zhuang-tai')}&nbsp;
                <Select
                  style={{ width: 90 }}
                  value={matchStatus ?? -1}
                  onChange={val => {
                    const v = val === -1 ? undefined : val
                    setMatchStatus(v)
                    setPage(1)
                    getList({ ...reqData, matchStatus: v, page: 1 })
                  }}
                >
                  <Select.Option value={-1}>{t('2-common.quan-bu')}</Select.Option>
                  <Select.Option value={0}>{matchStatusNameMap[0]}</Select.Option>
                  <Select.Option value={1}>{matchStatusNameMap[1]}</Select.Option>
                </Select>
              </Space>
            )}
            {userRule === 'finance' && (
              <Space size={0}>
                {t('Transaction.gong-si-yin-hang-ka-zhang-hao')}&nbsp;
                <Input.Search
                  style={{ width: 300 }}
                  enterButton
                  allowClear
                  placeholder={t('2-common.qing-shu-ru')}
                  onChange={e => setCompanyCardNumber(e.target.value.trim() || undefined)}
                  onSearch={(val, _e, info) => {
                    val = val.trim()
                    if (info?.source === 'clear' && !param?.companyCardNumber) return
                    setPage(1)
                    getList({ ...reqData, companyCardNumber: val, page: 1 })
                  }}
                />
              </Space>
            )}
            {userRule === 'employee' && (
              <Space size={0}>
                {t('Transaction.shang-jia-yin-hang-ka-zhang-hao')}&nbsp;
                <Input.Search
                  style={{ width: 300 }}
                  enterButton
                  allowClear
                  placeholder={t('2-common.qing-shu-ru')}
                  onChange={e => setUserCardNumber(e.target.value.trim() || undefined)}
                  onSearch={(val, _e, info) => {
                    val = val.trim()
                    if (info?.source === 'clear' && !param?.userCardNumber) return
                    setPage(1)
                    getList({ ...reqData, userCardNumber: val, page: 1 })
                  }}
                />
              </Space>
            )}
            <Space size={0}>
              {t('Transaction.shi-ji-dao-zhang-ri-qi')}&nbsp;
              <DatePicker.RangePicker
                style={{ width: 215 }}
                allowClear={false}
                disabledDate={getDisabledDateRangeFn(6)}
                presets={[
                  dateRangePresets.today,
                  dateRangePresets.yesterday,
                  dateRangePresets.thisWeek,
                  dateRangePresets.lastWeek,
                  dateRangePresets.thisMonth,
                  dateRangePresets.lastMonth,
                  dateRangePresets.last7days,
                  dateRangePresets.last30days,
                  dateRangePresets.last6months,
                ]}
                value={[dayjs(receivedDateBegin), dayjs(receivedDateEnd)]}
                onChange={(_dates, [receivedDateBegin, receivedDateEnd]) => {
                  setReceivedDateBegin(receivedDateBegin)
                  setReceivedDateEnd(receivedDateEnd)
                  setPage(1)
                  getList({ ...reqData, receivedDateBegin, receivedDateEnd, page: 1 })
                }}
              />
            </Space>
          </Space>
        </div>
        <div className={styles.header_action}>
          {userRule === 'finance' && (
            <Popover
              mouseLeaveDelay={0.2}
              placement="topRight"
              content={
                <Button
                  type="link"
                  icon={<DownloadOutlined />}
                  onClick={() => {
                    window.open(
                      `https://shipo-erp.oss-us-east-1.aliyuncs.com/excelTemplate/bank_statement_import_template.xlsx`,
                    )
                  }}
                >
                  {t('2-common.excel-mo-ban-xia-zai')}
                </Button>
              }
            >
              <Button type="primary" onClick={handleImport}>
                {t('2-common.excel-dao-ru')}
                <InfoCircleOutlined />
              </Button>
            </Popover>
          )}
        </div>
      </div>

      <div className={styles.table}>
        <CusTable
          rowKey="id"
          loading={{ spinning: loading, delay: SPIN_DELAY }}
          dataSource={data?.records}
          columns={columns}
          ref={tableRef}
          verticalFlexLayout
          pagination={{
            ...getDefaultPaginationProps(data?.total || 0),
            current: page,
            pageSize: size,
            onChange: (page, size) => {
              setPage(page)
              setSize(size)
              getList({ ...reqData, page, size })
            },
          }}
        />
      </div>

      <popupSlot.Slot />
    </div>
  )
}
