import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMemoizedFn } from 'ahooks'
import { Button, Drawer, Result, Space } from 'antd'

import { Loading } from '@/components/Loading'
import { apiGetDetail, apiGetPayBankRecords } from '../apis'
import { IDetail } from '../interface'
import { popupSlot } from '../utils'
import { Actions } from './Actions'
import { Ctx, ICtxVal } from './Ctx'
import { MainInfo } from './MainInfo'
import { ReceivedInfo } from './ReceivedInfo'

const updatePopup = popupSlot.insert(null)

type TProps = {
  /** 查看 */
  view: {
    /** 交易ID */
    transactionId: string
  }
  /** 审批 */
  approval: {
    /** 交易ID */
    transactionId: string
    /** 成功时调用 */
    onSuccess?: () => void
  }
  /** 提交 */
  submit: {
    /** 商家用户id */
    merchantUserId: string
    /** 需要填充的数据（只认初始值） */
    beforeFillData?: Pick<IDetail, 'merchantUserId' | 'payCardNumber'>
    /** 成功时调用 */
    onSuccess?: () => void
  }
  /** 重新提交 */
  resubmit: {
    /** 交易ID */
    transactionId: string
    /** 成功时调用 */
    onSuccess?: () => void
  }
}

interface DetailsDrawerProps<T extends ICtxVal['mode']> {
  /** view-查看; approval-审批; submit-提交; resubmit-重新提交; */
  mode: T
  props: TProps[T]
}

/** 查看、审批、提交、重新提交（银行流水页面的员工侧、资金流水页面都有调用，请不要依赖外部 Context） */
export const DetailsDrawer = Object.assign(
  <T extends ICtxVal['mode']>({ mode, props, destroy }: DetailsDrawerProps<T> & { destroy: () => void }) => {
    const { t } = useTranslation()
    const { open, onHide, afterOpenChange } = popupSlot.useAntdPopupAnimation(destroy)

    const [mainForm, setMainForm] = useState<ICtxVal['mainForm']>(null)
    const [approvalForm, setApprovalForm] = useState<ICtxVal['approvalForm']>(null)
    const [btnDisabled, setBtnDisabled] = useState(false)

    const [loading, setLoading] = useState<boolean | 0>(true)
    const [detail, setDetail] = useState<ICtxVal['detail']>()
    const [fillData, setFillData] = useState<ICtxVal['fillData']>()
    const [showNoMatch, setShowNoMatch] = useState(false)

    let merchantUserId: ICtxVal['merchantUserId']
    if (mode === 'submit' && 'merchantUserId' in props) {
      merchantUserId = props.merchantUserId
    }

    const tryGetData = useMemoizedFn(async () => {
      try {
        if (mode !== 'submit' && 'transactionId' in props) {
          const res = await apiGetDetail(props.transactionId)
          setDetail(res)
        }
        if (mode === 'submit' && 'beforeFillData' in props) {
          const { beforeFillData } = props
          if (beforeFillData) {
            const res = await apiGetPayBankRecords(beforeFillData.merchantUserId)
            const bank = res.find(d => d.cardNumber === beforeFillData.payCardNumber)
            if (bank) {
              setFillData({
                merchantUserId: beforeFillData.merchantUserId,
                payBankName: bank.bankName,
                payAccount: bank.account,
                payCardNumber: bank.cardNumber,
              })
            } else {
              setShowNoMatch(true)
            }
          }
        }
        await new Promise(r => setTimeout(() => r(null))) // 解决 use-context-selector 更新延时问题
        setLoading(false)
      } catch (err) {
        setLoading(0)
        throw err
      }
    })

    useEffect(() => void tryGetData(), [tryGetData])

    const actionsRender = () => {
      if (loading === true || loading === 0 || showNoMatch) return
      return (
        <Actions
          onSuccess={() => {
            onHide()
            if (mode !== 'view' && 'onSuccess' in props) props.onSuccess?.call(null)
          }}
        />
      )
    }

    const contentRender = () => {
      if (loading === true) return <Loading />
      if (loading === 0) return
      if (showNoMatch) return <Result status="error" title={t('Transaction.shang-jia-yin-hang-ka-wei-pi-pei')} />
      return (
        <>
          <MainInfo />
          {((mode === 'view' && detail?.rechargeStatus === 1) || mode === 'approval') && <ReceivedInfo />}
        </>
      )
    }

    return (
      <Ctx.Provider
        value={{
          mode,
          mainForm,
          setMainForm,
          approvalForm,
          setApprovalForm,
          btnDisabled,
          setBtnDisabled,
          detail,
          merchantUserId,
          fillData,
        }}
      >
        <Drawer
          title={
            {
              view: t('Transaction.chong-zhi-xiang-qing'),
              approval: t('Transaction.chong-zhi-shen-pi'),
              submit: t('Transaction.chong-zhi-ti-jiao'),
              resubmit: t('Transaction.chong-zhi-zhong-xin-ti-jiao'),
            }[mode]
          }
          width={888}
          open={open}
          onClose={onHide}
          afterOpenChange={afterOpenChange}
          extra={
            <Space>
              {actionsRender()}
              <Button onClick={onHide}>{t('2-common.guan-bi')}</Button>
            </Space>
          }
        >
          {contentRender()}
        </Drawer>
      </Ctx.Provider>
    )
  },
  {
    open: <T extends ICtxVal['mode']>(props: DetailsDrawerProps<T>) => {
      updatePopup(<DetailsDrawer {...props} destroy={() => updatePopup(null)} />)
    },
  },
)
