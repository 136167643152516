import { CSSProperties } from 'react'
import { Card, Space, theme } from 'antd'
import Icon from '@ant-design/icons'

import { NumPrivacyShow } from '@biz/components/NumPrivacyShow'
import { IBank } from '../interface'
import CardNumberIcon from '@/assets/icons/cardNumber.svg?react'

/** 银行卡片 */
export const BankCard = ({
  style,
  bank,
  checked,
  onClick,
}: {
  style?: CSSProperties
  bank: { [K in keyof Omit<IBank, 'id'>]: Omit<IBank, 'id'>[K] | null | undefined }
  checked?: boolean
  onClick?: () => void
}) => {
  const { token } = theme.useToken()
  return (
    <Card
      style={{
        borderColor: checked ? token.colorPrimary : undefined,
        width: 360,
        ...style,
      }}
      size="small"
      title={bank.bankName || '--'}
      extra={bank.account || '--'}
      onClick={() => onClick?.()}
    >
      <Space size={12} styles={{ item: { display: 'flex', alignItems: 'center' } }}>
        <Icon style={{ fontSize: '1.2em', color: token.colorTextPlaceholder }} component={CardNumberIcon} />
        <span style={{ fontSize: '1.2em' }}>{bank.cardNumber ? <NumPrivacyShow value={bank.cardNumber} /> : '--'}</span>
      </Space>
    </Card>
  )
}
