import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs, { Dayjs } from 'dayjs'

import { useGlobalState } from '@/globalStore/globalStore'

/** 获取用户角色 */
export const useRule = (): 'customer' | 'employee' | 'finance' | null => {
  const roleCodeMap = useGlobalState(s => s.userInfo?.roleCodeMap)
  if (roleCodeMap?.['102']) return 'customer'
  if (roleCodeMap?.['101']) return 'employee'
  if (roleCodeMap?.['103']) return 'finance'
  return null
}

/** 日期范围禁用（包括未来日期） */
export const getDisabledDateRangeFn = (maxMonth: number) => (curr: Dayjs, info: any) => {
  const from = info.from as Dayjs | undefined
  if (curr.endOf('day') > dayjs().endOf('day')) return true
  if (from) {
    const curMonth = curr.year() * 12 + curr.month()
    const fromMonth = from.year() * 12 + from.month()
    return Math.abs(fromMonth - curMonth) >= maxMonth
  }
  return false
}

/** 单据类型值:单据类型名称 */
export const useDOCUMENT_TYPE_NAME_MAP = (): Record<number, string | undefined> => {
  const { t } = useTranslation()
  return useMemo(
    () => ({
      1: t('Transaction.ti-huo-dan'),
      2: t('Transaction.ding-dan'),
      3: t('Transaction.chong-zhi-dan'),
      4: t('Transaction.jia-gong-dan'),
      5: t('Transaction.tui-huo-dan'),
    }),
    [t],
  )
}

/** 费用类目值:费用类目名称 */
export const useEXPENSE_TYPE_NAME_MAP = (): Record<number, string | undefined> => {
  const { t } = useTranslation()
  return useMemo(
    () => ({
      1: t('Transaction.ti-huo-fei'),
      2: t('Transaction.cang-chu-fei'),
      3: t('Transaction.cao-zuo-fei'),
      4: t('Transaction.chong-zhi'),
      5: t('Transaction.yun-fei'),
      6: t('Transaction.jia-gong-fei'),
      7: t('Transaction.vat-shou-xu-fei'),
      8: t('Transaction.vat-zeng-zhi-shui'),
      9: t('Transaction.gua-hao-fei'),
    }),
    [t],
  )
}
