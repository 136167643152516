import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useContextSelector } from 'use-context-selector'
import { useRequest } from 'ahooks'
import dayjs from 'dayjs'
import {
  Button,
  Col,
  Descriptions,
  Divider,
  Form,
  FormProps,
  InputNumber,
  message,
  Popconfirm,
  Popover,
  Row,
  Select,
  Space,
} from 'antd'
import { PlusOutlined, QuestionCircleOutlined, UploadOutlined } from '@ant-design/icons'

import { FormItemDatePicker } from '@/components/FormItemDateTime'
import { FormItemUpload } from '@/components/FormItemUpload'
import { FormItemValidatingInput } from '@/components/FormItemValidatingInput'
import { useFormAllValues } from '@/hooks/hooks'
import { fileValidate, formAutoTrimOnBlur, toThousands } from '@/utils/utils'
import { MerchantSelect } from '@biz/components/MerchantSelect'
import { useCURRENCY_DES_MAP, useCURRENCY_OPTS } from '@biz/consts/consts'
import { useRule } from '../../utils'
import { apiBankStatementVerification, apiGetExchangeRate } from '../apis'
import { BankCard } from '../components/BankCard'
import { MerchantBankCardSelect } from '../components/MerchantBankCardSelect'
import { ReceivedBankSelect } from '../components/ReceivedBankSelect'
import { IBank, TFormValues } from '../interface'
import { Ctx } from './Ctx'
import { createInitialFormValues } from './utils'

/** 充值商家 & 付款信息 */
export const MainInfo = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const userRule = useRule()
  const mode = useContextSelector(Ctx, v => v.mode)
  const setMainForm = useContextSelector(Ctx, v => v.setMainForm)
  const setBtnDisabled = useContextSelector(Ctx, v => v.setBtnDisabled)
  const detail = useContextSelector(Ctx, v => v.detail)
  const merchantUserId = useContextSelector(Ctx, v => v.merchantUserId)
  const fillData = useContextSelector(Ctx, v => v.fillData)

  const [form] = Form.useForm<TFormValues>()
  const initialFormValues = useMemo(() => createInitialFormValues(merchantUserId, null), [merchantUserId])
  const allValues = useFormAllValues(form, initialFormValues)
  const [resetCount, setResetCount] = useState(0)

  const readOnly = mode === 'view' || mode === 'approval'
  const currencyDesMap = useCURRENCY_DES_MAP()
  const currencyOpts = useCURRENCY_OPTS()

  const formProps: FormProps<TFormValues> = {
    form,
    scrollToFirstError: { block: 'center', behavior: 'smooth' },
    layout: 'vertical',
    preserve: true,
    initialValues: initialFormValues,
    onBlur: e => formAutoTrimOnBlur({ event: e, form, includeField: ['transferBankStatement'] }),
  }

  // 传送表单实例
  useEffect(() => setMainForm(form), [form, setMainForm])

  // 填充详情
  useEffect(() => {
    const values = createInitialFormValues(merchantUserId, detail || fillData)
    form.setFieldsValue(values)
    setResetCount(v => v + 1)
  }, [detail, fillData, form, merchantUserId])

  // 处理今日汇率
  const { loading: exchangeRateFetching, data: exchangeRateMap } = useRequest(async () => {
    if (mode === 'submit' || mode === 'resubmit') return apiGetExchangeRate()
  })
  useEffect(() => {
    if (mode === 'submit' || mode === 'resubmit') setBtnDisabled(exchangeRateFetching)
  }, [exchangeRateFetching, mode, setBtnDisabled])
  useEffect(() => {
    let val: string | undefined
    if (allValues.transferCurrencyType) {
      val = exchangeRateMap?.[allValues.transferCurrencyType]
    }
    form.setFieldValue('transferExchangeRate', val)
  }, [allValues.transferCurrencyType, exchangeRateMap, form])

  // 大标题
  const getMerchantLabel = () => (
    <Divider orientation="left" orientationMargin={0}>
      <b>{t('Transaction.shang-jia-xin-xi')}</b>
    </Divider>
  )
  const getPayLabel = () => (
    <Divider orientation="left" orientationMargin={0}>
      <b>{t('Transaction.fu-kuan-xin-xi')}</b>
    </Divider>
  )

  return readOnly ? (
    // 查阅模式
    <>
      {userRule !== 'customer' && (
        <>
          {getMerchantLabel()}
          <Descriptions
            size="small"
            bordered
            column={6}
            labelStyle={{ whiteSpace: 'nowrap' }}
            items={[
              {
                span: 3,
                label: t('Transaction.shang-jia-you-xiang'),
                children: detail?.merchantUserEmail || '--',
              },
              {
                span: 3,
                label: t('2-common.shang-jia-ming-cheng'),
                children: detail?.merchantUserName || '--',
              },
            ]}
          />
        </>
      )}
      {getPayLabel()}
      <Descriptions
        size="small"
        bordered
        column={6}
        labelStyle={{ whiteSpace: 'nowrap' }}
        items={[
          {
            span: 6,
            label: t('Transaction.fu-kuan-yin-hang-ka'),
            children: detail?.payCardNumber && (
              <BankCard
                bank={{
                  bankName: detail.payBankName,
                  account: detail.payAccount,
                  cardNumber: detail.payCardNumber,
                }}
              />
            ),
          },
          {
            span: 3,
            label: t('Transaction.zhuan-zhang-jin-e'),
            children: toThousands(detail?.transferAmount),
          },
          {
            span: 3,
            label: t('Transaction.zhuan-zhang-bi-zhong'),
            children: currencyDesMap[detail?.transferCurrencyType + ''] || '--',
          },
          {
            span: 6,
            label: t('Transaction.shou-kuan-yin-hang-ka'),
            children: [detail?.receivedBankName, detail?.receivedCardNumber].join(' - ') || '--',
          },
          {
            span: 6,
            label: t('Transaction.zhuan-zhang-liu-shui-hao'),
            children: detail?.transferBankStatement || '--',
          },
          {
            span: 3,
            label: t('Transaction.zhuan-zhang-ri-qi'),
            children: detail?.transferDate ? dayjs(detail.transferDate).format($F_YMD) : '--',
          },
          {
            span: 3,
            label: t('Transaction.ti-jiao-chong-zhi-dang-tian-hui-shuai'),
            children: detail?.transferExchangeRate ? `USD ${detail.transferExchangeRate}` : '--',
          },
          {
            span: 6,
            label: t('2-common.fu-jian'),
            children: (
              <Space wrap size={[12, 4]}>
                {detail?.attachmentList.map((d, i) => (
                  <a key={i} target="_blank" href={d.fileUrl} rel="noreferrer">
                    {d.fileName}
                  </a>
                ))}
              </Space>
            ),
          },
        ]}
      />
    </>
  ) : (
    // 编辑模式
    <Form {...formProps}>
      {userRule !== 'customer' && (
        <>
          {getMerchantLabel()}
          <Row gutter={12}>
            <Col span={15}>
              <Form.Item
                label={t('Transaction.chong-zhi-shang-jia')}
                name="merchantUserId"
                rules={[{ required: true }]}
              >
                <MerchantSelect
                  style={{ width: '100%', maxWidth: 'none' }}
                  allowClear={false}
                  getOptionLabel={info => `${info.email} - ${info.userName}`}
                  disabled={
                    !!fillData || // 填充的不让修改
                    mode === 'resubmit' // 重新提交的不让修改
                  }
                  onChange={() => {
                    form.setFieldValue('payAccount', '')
                    form.setFieldValue('payBankName', '')
                    form.setFieldValue('payCardNumber', '')
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={9} />
          </Row>
        </>
      )}
      {getPayLabel()}

      {userRule === 'customer' && (
        <div style={{ height: 0, position: 'relative' }}>
          <Popconfirm
            placement="topRight"
            title={t('Transaction.que-ren-yao-li-kai-ye-mian-qu-tian-jia-yin-hang-ka-ma')}
            onConfirm={() => navigate('/biz/setting/billing')}
          >
            <Button
              style={{
                position: 'absolute',
                right: 0,
                top: 0,
                zIndex: 2,
              }}
              type="link"
              icon={<PlusOutlined />}
            >
              {t('Transaction.qu-tian-jia-yin-hang-ka')}
            </Button>
          </Popconfirm>
        </div>
      )}
      <Form.Item label={t('Transaction.fu-kuan-yin-hang-ka')} name="payCardNumber" rules={[{ required: true }]}>
        {fillData /* 填充的不让修改 */ ? (
          <Row gutter={12}>
            <Col span={12}>
              <BankCard
                style={{ width: 'auto' }}
                checked
                bank={{
                  bankName: allValues.payBankName,
                  account: allValues.payAccount,
                  cardNumber: allValues.payCardNumber,
                }}
              />
            </Col>
            <Col span={12} />
          </Row>
        ) : (
          <MerchantBankCardSelect
            merchantUserId={allValues.merchantUserId}
            onChange={(_v, d) => {
              form.setFieldValue('payBankName', d.bankName)
              form.setFieldValue('payAccount', d.account)
            }}
            onFetch={({ fetching, banks, error }) => {
              const setBank = (bank: Omit<IBank, 'id'> | null) => {
                form.setFieldValue('payBankName', bank?.bankName)
                form.setFieldValue('payAccount', bank?.account)
                form.setFieldValue('payCardNumber', bank?.cardNumber)
              }
              if (banks) {
                if (mode === 'submit') {
                  if (banks.length === 1) setBank(banks[0]) // 只有一个选项时自动选中
                }
                if (mode === 'resubmit') {
                  const target = banks.find(d => d.cardNumber === allValues.payCardNumber)
                  if (!target) setBank(null) // 未匹配
                }
              } else if (error) {
                setBank(null)
              }
              form.setFields([{ name: 'payCardNumber', validating: fetching }])
            }}
          />
        )}
      </Form.Item>

      <Row wrap gutter={12}>
        <Col span={15}>
          <Form.Item label={t('Transaction.zhuan-zhang-jin-e')} name="transferAmount" rules={[{ required: true }]}>
            <InputNumber
              style={{ width: '100%' }}
              min={0.01}
              precision={2}
              stringMode
              formatter={v => toThousands(v, { placeholder: '', precision: null })}
              placeholder={t('2-common.qing-shu-ru')}
            />
          </Form.Item>
        </Col>

        <Col span={9}>
          <Form.Item
            label={t('Transaction.zhuan-zhang-bi-zhong')}
            name="transferCurrencyType"
            rules={[{ required: true }]}
          >
            <Select popupMatchSelectWidth={false} options={currencyOpts} placeholder={t('2-common.qing-xuan-ze')} />
          </Form.Item>
        </Col>

        <Col span={15}>
          <Form.Item label={t('Transaction.shou-kuan-yin-hang-ka')} name="receivedCardNumber">
            <ReceivedBankSelect
              onChange={(_v, { bankName }) => {
                form.setFieldValue('receivedBankName', bankName)
              }}
            />
          </Form.Item>
        </Col>

        <Col span={15}>
          <Form.Item
            label={t('Transaction.zhuan-zhang-liu-shui-hao')}
            name="transferBankStatement"
            validateTrigger="onBlur"
            rules={[
              {
                async validator(_r, val) {
                  const v = `${val || ''}`.trim()
                  if (!v) return
                  const res = await apiBankStatementVerification({ bankStatement: v, type: 1 })
                  if (res !== true) throw new Error(t('Transaction.gai-liu-shui-hao-yi-chu-li-guo'))
                },
              },
            ]}
          >
            <FormItemValidatingInput />
          </Form.Item>
        </Col>

        <Col span={15}>
          <Form.Item label={t('Transaction.zhuan-zhang-ri-qi')} name="transferDate">
            <FormItemDatePicker
              style={{ width: '100%' }}
              disabledDate={curr => curr.endOf('day') > dayjs().endOf('day')}
            />
          </Form.Item>
        </Col>

        <Col span={9}>
          <Form.Item label={t('Transaction.jin-ri-hui-shuai')}>
            <span style={{ marginLeft: 3 }}>
              {allValues.transferExchangeRate ? (
                <Space size={12}>
                  USD
                  <>{allValues.transferExchangeRate}</>
                  <Popover mouseLeaveDelay={0} content={t('Transaction.can-kao-hui-lu-tip')}>
                    <QuestionCircleOutlined />
                  </Popover>
                </Space>
              ) : (
                '--'
              )}
            </span>
          </Form.Item>
        </Col>

        <Col span={15}>
          <Form.Item label={t('2-common.fu-jian')}>
            <FormItemUpload
              key={resetCount /* 处理初始填充 */}
              reqData={{ moduleType: 4 }}
              maxCount={3}
              beforeUpload={file => {
                const msg = fileValidate(file, { max: 10 })
                if (msg) {
                  message.error(msg)
                  return FormItemUpload.LIST_IGNORE
                }
              }}
              initialNameMap={allValues.attachmentList.reduce((o, d) => ({ ...o, [d.fileUrl]: d.fileName }), {})}
              value={allValues.attachmentList.map(d => d.fileUrl)}
              onChange={(urls, names) => {
                form.setFieldValue(
                  'attachmentList',
                  urls.map((fileUrl, i) => ({ fileUrl, fileName: names[i] })),
                )
              }}
            >
              <Button icon={<UploadOutlined />} disabled={allValues.attachmentList.length >= 3}>
                {t('2-common.shang-chuan-fu-jian')}
              </Button>
            </FormItemUpload>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}
